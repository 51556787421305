<template>
	<w-dialog v-model="dialog" :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'" :title="$t('signature.documents.signatories_list')" @close="close()">
		<w-layout v-if="loading" column align-center justify-center class="pb-3 pt-3">
			<v-progress-circular :value="80" indeterminate></v-progress-circular>
		</w-layout>
		<w-layout v-else column>
			<w-text-info :text="infoMessageSignatories" />
			<w-list style="width: 100%">
				<w-divider />
				<template v-for="signatory in signatories">
					<w-list-tile :key="signatory.id" avatar>
						<w-list-tile-avatar>
							<v-avatar color="primary" size="2.5em">
								<w-flex white--text>{{ signatory.abbreviation }}</w-flex>
							</v-avatar>
						</w-list-tile-avatar>
						<w-list-tile-content>
							<w-list-tile-title v-text="signatory.username" />
							<w-list-tile-sub-title v-text="signatory.email" />
						</w-list-tile-content>
						<w-divider vertical />
						<w-list-tile-action :class="`${signatory.status.color}--text font-weight-bold`">
							{{ $t(`signature.documents.statuses.${signatory.status.name}`) }}
						</w-list-tile-action>
					</w-list-tile>
					<w-divider :key="`${signatory.id}-divider`" />
				</template>
			</w-list>
		</w-layout>
	</w-dialog>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'

export default {
	name: 'SignatoriesList',
	mixins: [SignatureModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			dialog: false,
			loading: false,
			signatories: []
		}
	},
	computed: {
		infoMessageSignatories: function () {
			let message = this.$t('signature.document.signatories_list_info')
			return this.signatories.length > 1 ? message + ' ' + this.$t('signature.documents.add_several_signatories_information') : message
		}
	},
	created: function () {
		this.listSignatories()
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		close: function () {
			this.dialog = false
			this.loading = false
			this.$emit('close')
		},
		listSignatories: function () {
			this.loading = true
			return this.service
				.getSignatories(this.accountingFirmId, this.vendorId, this.value.id)
				.then(signatories => {
					this.signatories.splice(0, this.signatories.length, ...signatories.signatories)
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>
